export const RU =`Политика конфиденциальности мобильного приложения Greenbin.app

I. Общие положения
1. Настоящая Политика конфиденциальности (далее: "Политика конфиденциальности") устанавливает правила защиты и обработки персональных данных в мобильном приложении Greenbin.app (определенные ниже).

2. Администратором персональных данных, обрабатываемых в рамках Заявки, является Greenbin sp. Z o.o. со штаб-квартирой в Познани, ul. Szelągowska 49 (61-626 Poznań), внесена в Реестр предпринимателей Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań-Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000803248, REGON: 384329544, NIP: 9721303267 (далее : "" Администратор "" или "" Поставщик услуг "»).

3. Администратор указывает следующие контактные данные: e-mail: hello@greenbin.app.

    II. Определение
Термины, используемые в Политике конфиденциальности, имеют следующее значение:

    1. Приложение - мобильное приложение под названием "Greenbin.app", по которым пользователи, принимая вызов, получать зеленые очки, которые можно обменять на призы или подарить на благотворительность.

2. GDPR - Регламент (ЕС) 2016/679 Европейского Парламента и Совета от 27 апреля 2016 о защите физических лиц при обработке персональных данных и о свободном перемещении таких данных, а также об отмене Директивы 95/46 / ЕС (общее положение о защите данных).

3. Зеленые баллы - средство обмена с потребительской стоимостью только в рамках Программы, не является виртуальной валютой. Пользователи, которые собирают зеленые очки, могут обменять их на призы или пожертвовать на благотворительные цели.

4. Вызовы - деятельность в области сбора и переработки пластиковых бутылок, предусмотрена в Приложении, за выполнение которой Пользователи зарабатывают зеленые очки.

5. Вознаграждение - удовольствие, которое Пользователь имеет право получить после выполнения определенных условий: Пользователь получает вознаграждение, когда обменяет соответствующее количество зеленых баллов в магазине Greenbin Store. Призы, в частности, могут иметь форму акции, скидки, ваучера или материального приза.

6. Greenbin Store - платформа, которая работает как часть приложения, позволяющие пользователям заказывать вознаграждения в обмен на собранные Зеленые баллы.

7. Пользователь - физическое лицо, отвечающее указанным условиям для участия в Приложении, включая, в частности, регистрацию в Заявке.

8. Push-оповещения - сообщения о Программы, включая информирование пользователя о вызовах, возникающих в его зоне, предоставляемых Пользователю как часть Программы.

9. Клиент - предприниматель в понимании статьи 4 Закона от 6 марта 2018 - Закон о предпринимателей (то есть Закон о законах 2019 года, пункт 1292, с изменениями).

III. Объем данных, обработанных в приложении
1. Приложение обрабатывает такие персональные данные Пользователей:

    - Данные, предоставленные пользователем при регистрации в Приложении: имя / имя игрока (ник), адрес электронной почты, пол, дата рождения, возраст.

- Данные автоматически собираются приложением: местонахождение мобильного устройства, IP-адрес, информация о собранных Green Points, информация о транзакции в магазине Greenbin Store, информация о Green Points, переданы благотворительным фондам, информация о расстоянии, которую Пользователь преодолел как часть с помощью программы. Обработка этих данных Администратором не имеет негативных последствий для пользователя. Эти данные обрабатываются только с целью выполнения контракта, аналитики, статистики и безопасности.

- Данные, которые добровольно предоставляются Пользователем при использовании приложения: изображение профиля / аватара, город проживания, образование, семейное положение, информация о детях.

- Данные, необходимые для выполнения заказов на материальные награды от магазина Greenbin: имя и фамилия, адрес электронной почты, адрес корреспонденции.

- Данные, необходимые для того, чтобы администратор мог ответить на представленные нарушения адрес электронной почты.

- Данные, необходимые администратору для проверки (подтверждения) зеленых точек фото бутылок, Селфи или фото пользователя перед корзиной с бутылками, а также адрес корзины, в который бросили бутылки. Эти данные будут отправлены пользователями на страницу Greenbin в Facebook через Messenger: https://m.me/greenbinapp. После проверки данные будут удалены.

    2. Данные, которые собирает Поставщик услуг, предназначенные для таких целей:

    - Адрес электронной почты - используется для регистрации и входа в Приложение. Код подтверждения будет выслан на указанный электронный адрес.

- Имя / имя игрока (псевдоним) - используется для идентификации пользователя Применением, позволяет позиционировать пользователя в рейтингах в Приложении.

- Информация о местонахождении устройства - необходима для нормального функционирования Программы, в частности для того, чтобы дать возможность Пользователю участвовать в вызове и указывать Вызовы на карте. Администратор не сохраняет историческую информацию о местонахождении устройства.

- IP-адрес - обрабатывается для статистических и аналитических целей и по соображениям безопасности.

- Информация о собранных Green Points, о транзакции в магазине Greenbin Store, о Green Points, переданы благотворительным фондам, и о расстоянии, которую Пользователь преодолел как часть использование Программы обрабатываются для того, чтобы Пользователь мог правильно использовать приложение, а также с целью улучшения функциональных возможностей Программы, включая возможность пользователя проверить историю его деятельности согласно Приложению.

- Пол, дата рождения, возраст, город проживания, образование, семейное положение, информация о детях - обрабатываются с целью предоставления Пользователю более релевантных отображаемых объявлений, а также с целью создания анонимной статистики администрацией.

- Изображения профиля / аватар - используется для обозначения пользователя в Приложении.

- Адрес электронной почты - необходимое для отправки призов в натуральной форме, а также для общения в области мыслей, комментариев, жалоб пользователя. Кроме того, в случае добровольного согласия пользователя, указан адрес электронной почты будет использована для связи с Заказчиком с целью продвижения услуг Поставщика услуг.

- Имя и фамилия и почтовый адрес - данные, необходимые для заполнения заказов на доставку материальных призов.

- Фото бутылок, Селфи или фото пользователя перед корзиной с бутылками, а также адрес корзины, в который бросили бутылки - данные, необходимые для проверки (подтверждения) зеленых точек.

3. С помощью настроек программы Пользователь может изменить такие категории персональных данных: имя игрока (ник), изображение профиля / аватара, электронную почту, пароль.

    IV. Правовые основы обработки персональных данных
1. Администратор обрабатывает персональные данные пользователей, такие как номер телефона, имя игрока (псевдоним), электронный адрес, пол, дату рождения, местонахождение пользователя, а также информацию о собранных Green Points и транзакции в магазине Greenbin, информацию о Green Points, подаренные благотворительным фондам. информация о расстоянии, которую Пользователь преодолел в рамках использования приложения, в целях исполнения договора о предоставлении электронных услуг, который Пользователь заключает с администрацией, зарегистрировавшись в Приложении (статья 6 (1) (b) GDPR).

2. Правовая основа для обработки данных в маркетинговых целях (пол, возраст, адрес электронной почты, изображение профиля / аватара, город проживания, образование, семейное положение, информация о детях), фото бутылок, Селфи или фото пользователя перед ним корзины для бутылок, а также адрес корзины, куда бросили бутылки, является добровольным согласием на обработку данных (статья 6 (1) (а) GDPR).

3. Правовая основа для обработки данных с целью согласования содержания Программы, Вызовов и других функциональных возможностей с интересами пользователя, а также для выявления мошенничества и статистических измерений и улучшения предоставляемых услуг (IP-адрес, информация о собранных Зеленые точки и транзакции в магазине Greenbin, о Green Points, переданы благотворительным фондам, информация о расстоянии, которую Пользователь прошел в рамках использования Программы, фотография профиля / аватара, фото бутылок, Селфи или фотографии пользователя перед корзиной для бутылок, а также адрес корзины, куда бросили бутылки), это законный интерес Администратора (статья 6 (1) (f) GDPR).

4. Мы обрабатываем персональные данные людей, которые используют магазин Greenbin, и заполняем для этого их данные (электронная почта, имя и фамилия, адрес для корреспонденции), поскольку это необходимо для целей договора (6 (1) (b) GDPR) . Кроме того, иногда закон требует от нас обработки определенных персональных данных для целей налогообложения и бухгалтерского учета (6 (1) (c) GDPR).

5. Важной частью предоставляемой в соответствии с Соглашением, заключенным пользователем, является предоставление рекламной информации о Клиентов, которую Пользователь признает и принимает. С другой стороны, пользователь имеет возможность собирать зеленые очки, обмениваться на Призы в магазине Greenbin и выигрывать призы.

6. Данные пользователя также обрабатываются как часть законных интересов Администратора, которая заключается в:

    - подготовке анонимной статистики на основе данных пользователя и выявления ошибок в работе приложения - с целью обеспечения его надлежащей работы и улучшения функциональных возможностей, а также для прямого маркетинга услуг Администратора;

- выявлении злоупотреблений при использовании Программы и заказ наград, а также выявления действий пользователей, которые не отвечают Положением о заявках (правовая основа: Статья 6 (1) (f) GDPR).

7. Заявка может также позволить представление Администратором или другими субъектами дополнительного согласия на обработку персональных данных. В этом случае правовой основой для обработки будет дано согласие (правовая основа: статья 6 (1) (а) GDPR).

V. Информация по обработке персональных данных
1. Администратор обрабатывает только данные, предоставленные в процессе регистрации и полученные при использовании пользовательских приложений, и полученные на странице Greenbin в Facebook через Messenger: https://m.me/greenbinapp.

    2. Предоставляя данные третьих лиц, Пользователь обязан получить их согласие - недопустимо предоставлять данные третьих лиц без правового основания. Если Пользователь предоставил данные третьих лиц во время регистрации или использования приложения, причинив ущерб Администратор имеет право требовать от пользователя надлежащей компенсации. Если такие действия запрещена действием, пользователь может предоставлять информацию о пользователе компетентным государственным органам.

3. Что касается лиц в возрасте до 16 лет, согласие на обработку персональных данных может выразить только законный представитель (родитель, опекун) такого лица. Отсутствие согласия законного представителя нарушает правила предоставления электронных услуг, описаны в Регламенте, и должно привести к удалению учетной записи и удаления Программы для мобильных устройств.

4. Приложение использует камеру мобильного устройства пользователя для обеспечения работы основных функций программы (сбор зеленых баллов, сканирования QR-кодов, сканирования штрих-кодов). Администратор не обрабатывает любые данные с помощью камеры мобильного устройства пользователя. В частности, изображение камеры не предоставляется или сохраняется любым образом Администратором или любыми другими организациями.

5. Предоставление персональных данных пользователями является добровольным. Однако предоставление данных, необходимых для регистрации аккаунта в Приложении, необходимые для заключения договора с администрацией и использования приложения.

6. Персональные данные пользователей защищены техническими и организационными мерами, которые гарантируют адекватный уровень защиты в соответствии с действующими нормами.

    VI. Получатели персональных данных
1. Если эти Политика или Положением о программах четко не предусматривают иное, Администратор не разглашает данные другим организациям.

2. Администратор может оказывать персональные данные на основании приказа, выданного судом, полицией или вследствие прав других лиц, вытекающие из законодательных положений.

3. Администратор предоставляет персональные данные пользователей следующим лицам:

    - Google LLC (для выполнения push-уведомлений как части Программы, а также для проведения анализа приложений - когда Приложение установлено на мобильном устройстве с операционной системой Android)

- Amazon Web Services Inc. (Для того, чтобы позволить Администратору проверить пользователя по электронной почте или SMS)

- Apple Inc. (Для того, чтобы выполнять push-оповещения как часть Программы, а также проводить анализ приложений - когда Приложение установлено на мобильном устройстве с операционной системой iOS)

- Amazon Web Services Inc. (Организация, ответственная за предоставление сервера)

- Amazon Web Services Inc. (Для управления push-уведомлениями)

- Google Cloud Platform, Google LLC (организация, ответственная за функционирование Карты и навигационного приложения в Приложении).

4. Данные обрабатываются компанией Google LLC согласно принципам Щита конфиденциальности между ЕС и США (Исполнительное решение Комиссии (ЕС) 2016/1250 от 12 июля 2016 года, принятое в соответствии с Директивой 95/46 / ЕС Европейского Парламента и Совет по адекватности Щитом конфиденциальности (Законодательный вестник Европейского Союза от 2016 г. № 207, стр. 1). Пользователь может проверить соответствие вышеуказанным лицам стандартам через портал https://www.privacyshield.gov/list.
    Что касается обработки данных Apple Inc. эти юридические лица обязались придерживаться персональных данных в соответствии с принятой ими политики конфиденциальности:

    - Apple Inc .: https://www.apple.com/pl/legal/privacy/

    5. Мы используем подрядчиков для размещения наших платформ и поддержки резервных копий. Нашими процессорами являются: Amazon Web Services, Inc., 410 Terry Drive Ave. North, WA 98109-5210 Сиэтл, США (данные обрабатываются только в ЕС). Эти данные будут обрабатываться этими организациями, пока данные будут храниться на наших платформах на основе целей, определенных в этой Политике конфиденциальности.
    Передача данных компании Google LLC, Apple Inc., Amazon Web Services Inc, Google Cloud Platform, Google LLC необходима для исполнения договора на оказание электронных услуг как части Приложения.

6. Пользователи могут просматривать достижения других пользователей на панели приложений, имея при этом доступ к таким личных данных, как: аватар, псевдоним пользователя и номер Green Point, который они имеют, а также информацию о полученном уровень пользователя.

    VII. Период обработки персональных данных
1. Персональные данные пользователей обрабатываются только на время действия договора между пользователем и администрацией (то есть с момента регистрации в Приложении к удалению аккаунта в Приложении - отказ от предоставленной услуги).

2. Удаление Программы или выход из нее не приводит к сбору данных о пользователе.

3. Удаление аккаунта в Приложении приводит к удалению и анонимности всех персональных данных, касающихся пользователя в соответствии с пунктом 5 ниже.

4. Администратор может разорвать соглашение с пользователем - удалить учетную запись пользователя и персональные данные в случаях, указанных в Положениях о заявках. Если Администратору нужно изменить или удалить данные пользователя на основании этой Политики конфиденциальности, Положение о заявке или закона Администратор сообщит пользователя об этом факте.

5. Персональные данные пользователя могут обрабатываться дольше, чем это следует из предыдущих пунктов, если это необходимо из-за жалобы или претензии, представленную Пользователем (до решения дела), в результате обязательства, вытекающего из правовых положений или распоряжение суда, полиции или других уполномоченных органов или возможной требования Администратора.

    VIII. Разрешения пользователя:
    1. Каждый Пользователь имеет право:
    получать информацию об обработке его персональных данных, включая категории обрабатываемых персональных данных и возможных получателей данных,
    просьба исправить неправильные персональные данные или дополнить неполные персональные данные,
    запросы на удаление или ограничение обработки персональных данных, и запрос будет выполнен, если законодательные требования по такому запросу будут выполнены,
    возражать против обработки его персональных данных,
    отозвать согласие на обработку персональных данных (если обработка осуществляется на основании согласия пользователя) - но это не влияет на законность обработки, которая имела место до отзыва согласия,
    передача персональных данных - путем получения данных от Администратора в формате, который позволяет передавать выбранной третьей стороне,
    подать жалобу в контролирующий орган - Президента Управление защиты персональных данных, ул. Stawki 2, 00 - 193 Warszawa - если будет обнаружено, что персональные данные обрабатываются вопреки закону.

2. Пользователь может подать заявку, вопрос или запрос, связанные с обработкой его персональных данных, отправив сообщение на электронный адрес: hello@greenbin.app.

    IX. заключительные положения
1. Администратор оставляет за собой право изменить содержание этой Политики конфиденциальности в случае изменения функционирования Приложения, влияющим на объем и способ обработки персональных данных пользователя, а также в случае изменения закона или получения решения или решения, заставит такие изменения.

2. Администратор приложит максимум усилий, чтобы сообщить об изменениях в содержании Политики конфиденциальности минимум за 14 дней. Об изменении содержания Политики конфиденциальности будет сообщено соответствующим сообщением в Приложении или отправкой сообщения на электронные адреса Пользователей (если указано).

3. Если изменение Политики конфиденциальности не затрагивает прав или обязанностей уже зарегистрированных пользователей и администраторов, или если это изменение не требует более широкого объема или целей обработки персональных данных, чем первоначально было объявлено в Политике - новое содержание Политики конфиденциальности не требует подтверждения пользователем. В других случаях изменение Политики требует Пользователем (способом, указанным в сообщении). В случае отсутствия такого подтверждения пользователь может принять решение о расторжении договора на использование Программы с Пользователем после вступления в силу такого изменения (согласно информации, содержащейся в сообщении о внесении изменений в Политику конфиденциальности). ), Которая состоится не ранее чем через 14 дней с момента отправки уведомлений Пользователю.

4. Принятие изменений также считается входом пользователя в Приложение после даты вступления в силу изменений, а также первым действием, выполненной пользователем в приложении, после вступления изменений в силу, если Пользователь остается вошел в систему в то время. Если изменения не принимаются, Пользователь должен выйти из услуги на условиях, предусмотренных Регламентом, до того, как изменения вступят в силу.

5. В вопросах, не предусмотренных Политикой конфиденциальности, применяются положения Регламента заявки и обязательные положения закона.

6. Эта Политика конфиденциальности охватывает функционирования Программы на территории Республики Польша. Возможно использование Программы за пределами территории Республики Польша может происходить на отдельно утвержденных условиях. В случае, если эта Политика конфиденциальности применяется за рубежом, ее положения не исключают права пользователя на осуществление прав, вытекающих из обязательных положений местного (иностранного) законодательства.

7. Настоящая Политика конфиденциальности вступает в силу 14 сентября 2020.`
export const EN =`Privacy Policy of Greenbin.app Mobile Application

I. General Provisions

1. This Privacy Policy (hereinafter "Privacy Policy") sets out the rules for the protection and processing of personal data in the Greenbin.app mobile application (defined below).

2. The administrator of the personal data processed within the Application framework is Greenbin sp. z o.o., headquartered in Poznan, ul. Szelągowska 49 (61-626 Poznań), entered in the Register of Entrepreneurs "Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań-Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000803248, REGON: 384329544, NIP: 9721303267" (hereinafter "Administrator" or "Service Provider").

3. The administrator specifies the following contact information:
e-mail: hello@greenbin.app

II. Definitions

The terms used in the Privacy Policy have the following meanings:

1. Application – a mobile application called "Greenbin.app", through which users accepting a challenge receive green points that can be exchanged for prizes or donated to charity.
2. GDPR – Regulation (EU) 2016/679 of the European Parliament and of the Council of April 27, 2016, on the Protection of Individuals about the processing of personal data and the free movement of such data.
3. Green Points – a medium of exchange with a consumer value only within the framework of the Program, it is not a virtual currency. Users who collect green points can redeem them for prizes or donate to charities.
4. Challenges – activities in the field of collection and recycling of plastic bottles, provided in the Application, for the completion of which Users earn green points.
5. Reward – a prize that the User has the right to receive after fulfilling certain conditions: The User receives a reward when they exchange the corresponding number of green points.
6. Greenbin Store – a platform within the application that allows users to order rewards in exchange for the collected Green Points.
7. User – an individual who meets the specified conditions for participation in the Application, including, in particular, registration in the Application Form.
8. Push notifications – messages about the Program, including informing the user about available challenges and updates.
9. Client – an entrepreneur within the meaning of Article 4 of the Law of 6 March 2018 - The Law on Entrepreneurs.

III. The Volume of Data Processed in the Application

1. The Application processes personal data, including:

   - Data provided during registration: username, email address, gender, date of birth.
   - Automatically collected data: device location, IP address, information on collected Green Points, transactions, and charitable contributions.
   - Voluntarily provided data: profile image, city of residence, education, marital status, children's information.
   - Data required for reward fulfillment: full name, email, mailing address.
   - Data for verifying user participation: photos of bottles, selfies, and basket locations.

2. Data is used for:

   - Ensuring proper functionality of the application.
   - Enhancing security and fraud prevention.
   - Analyzing user behavior to improve services.
   - Providing personalized advertising and promotional offers.
   - Compliance with legal obligations where applicable.

IV. Legal Bases of Personal Data Processing

1. Data processing is based on:
   - Contract performance (Art. 6 (1) (b) GDPR) – for account registration, challenges, and rewards.
   - User consent (Art. 6 (1) (a) GDPR) – for marketing, voluntary data sharing, and photo verification.
   - Legitimate interest (Art. 6 (1) (f) GDPR) – fraud detection, application improvement, statistical measurement.
   - Legal obligations (Art. 6 (1) (c) GDPR) – for tax and accounting compliance.

V. Protection Against Child Sexual Abuse and Exploitation (CSAE)

1. Strict Prohibition  
   Greenbin.app strictly prohibits any content or activities related to child sexual abuse, exploitation, or harm. We do not permit the uploading, sharing, storing, or distribution of any materials violating this policy.

2. Measures to Protect Minors  
   - The Application is not intended for use by individuals under 16 without parental or guardian consent.  
   - We do not knowingly collect personal data from minors without verified parental consent.  
   - Any suspicious activities involving minors will be promptly investigated, and relevant data may be reported to law enforcement authorities.

3. Reporting and Response Mechanism  
   If you discover content or behavior violating this policy, please report it immediately via:  
   - Email: hello@greenbin.app
   - Facebook Messenger: https://m.me/greenbinapp 

4. Cooperation with Law Enforcement  
   Any violations of CSAE policies may be reported to relevant law enforcement agencies or child protection organizations.

VI. Recipients of the Personal Data

1. Personal data may be disclosed to the following categories of recipients:
   - Service providers responsible for IT infrastructure and data storage (e.g., Amazon Web Services, Google Cloud, PowerBI).
   - Analytical and marketing partners to enhance user experience and personalize content.
   - Law enforcement or governmental authorities if required by applicable law.
   - Payment service providers for transaction processing related to Greenbin Store purchases.

2. The Administrator ensures that all third-party data processing complies with relevant data protection regulations.

VII. Personal Data Processing Period

1. User data is processed for the duration of the agreement between the user and the Administrator (from registration until account deletion).
2. Some data may be retained longer where required by legal obligations, such as tax and accounting requirements.
3. If a user submits a complaint, their data may be retained until the issue is resolved.
4. Once the processing period expires, personal data will be securely deleted or anonymized.

VIII. User Permissions

1. Users have the right to:
   - Access their personal data and request information about its processing.
   - Request corrections to incorrect or incomplete data.
   - Request deletion or restriction of processing, subject to legal conditions.
   - Object to data processing based on legitimate interest.
   - Withdraw consent at any time (where processing is based on consent).
   - Request data portability.
   - Complain with the relevant data protection authority.

2. Requests related to personal data processing can be submitted via email at: hello@greenbin.app.

IX. Final Provisions

1. The Administrator reserves the right to update this Privacy Policy in case of changes in application functionality, data processing methods, or legal requirements.
2. Users will be notified of significant policy changes at least 14 days in advance through the application or email.
3. Continued use of the application after policy changes take effect will be considered acceptance of the new terms.
4. If a user does not accept the changes, they may terminate their agreement with the Administrator and stop using the application.
5. This Privacy Policy applies primarily within the territory of Ukraine but may extend to international users under local data protection laws.
6. This Privacy Policy is effective as of January 01, 2025.`
export const PL = `Polityka Prywatności Aplikacji Mobilnej Greenbin.app
I. Postanowienia ogólne
Niniejsza Polityka Prywatności (dalej: „Polityka Prywatności”) określa zasady ochrony i przetwarzania danych osobowych w aplikacji mobilnej Greenbin.app (zdefiniowanej poniżej).


Administratorem danych osobowych przetwarzanych w ramach Aplikacji jest Greenbin sp. z o.o., z siedzibą w Poznaniu, ul. Szelągowska 49 (61-626 Poznań), wpisana do Rejestru Przedsiębiorców Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań-Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000803248, REGON: 384329544, NIP: 9721303267 (dalej: „Administrator” lub „Usługodawca”).


Administrator podaje następujące dane kontaktowe:
 e-mail: hello@greenbin.app



II. Definicje
Aplikacja – aplikacja mobilna o nazwie "Greenbin.app", w której użytkownicy, akceptując wyzwania, zdobywają „zielone punkty”, które mogą wymieniać na nagrody lub przekazywać na cele charytatywne.
GDPR – Rozporządzenie (UE) 2016/679 Parlamentu Europejskiego i Rady z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych oraz w sprawie swobodnego przepływu takich danych.
Zielone punkty – środek wymiany, który ma wartość konsumencką wyłącznie w ramach Programu. Nie jest to waluta wirtualna. Użytkownicy mogą wymieniać zebrane zielone punkty na nagrody lub przekazywać je na cele charytatywne.
Wyzwania – działania w zakresie zbierania i recyklingu plastikowych butelek dostępne w Aplikacji, za które Użytkownicy zdobywają zielone punkty.
Nagroda – nagroda, którą Użytkownik ma prawo otrzymać po spełnieniu określonych warunków. Użytkownik otrzymuje nagrodę, gdy wymieni odpowiednią liczbę zielonych punktów.
Sklep Greenbin – platforma w ramach Aplikacji, która umożliwia użytkownikom zamawianie nagród w zamian za zgromadzone Zielone punkty.
Użytkownik – osoba fizyczna, która spełnia określone warunki uczestnictwa w Aplikacji, w tym w szczególności dokonała rejestracji poprzez Formularz rejestracyjny.
Powiadomienia push – wiadomości dotyczące Programu, w tym informujące Użytkownika o dostępnych wyzwaniach i aktualizacjach.
Klient – przedsiębiorca w rozumieniu artykułu 4 Ustawy z dnia 6 marca 2018 r. – Prawo Przedsiębiorców.

III. Zakres przetwarzanych danych osobowych w aplikacji
Aplikacja przetwarza następujące dane osobowe:


Dane podane podczas rejestracji: nazwa użytkownika, adres e-mail, płeć, data urodzenia.
Dane zbierane automatycznie: lokalizacja urządzenia, adres IP, informacje o zebranych Zielonych Punktach, transakcjach i darowiznach na cele charytatywne.
Dane podawane dobrowolnie: zdjęcie profilowe, miasto zamieszkania, wykształcenie, stan cywilny, informacje o dzieciach.
Dane wymagane do realizacji nagród: imię, nazwisko, adres e-mail, adres korespondencyjny.
Dane weryfikujące uczestnictwo użytkownika: zdjęcia butelek, selfie, lokalizacje koszy.
Dane są wykorzystywane do:


Zapewnienia prawidłowego funkcjonowania Aplikacji.
Poprawy bezpieczeństwa i zapobiegania oszustwom.
Analizy zachowań użytkowników w celu ulepszenia usługi.
Personalizacji reklam i ofert promocyjnych.
Realizacji zobowiązań prawnych.

IV. Podstawy prawne przetwarzania danych osobowych
Przetwarzanie danych odbywa się na podstawie:
Realizacji umowy (art. 6 ust. 1 lit. b RODO) – w zakresie rejestracji konta, udziału w wyzwaniach, odbioru nagród.
Zgody użytkownika (art. 6 ust. 1 lit. a RODO) – w celach marketingowych, dobrowolnego podania danych, weryfikacji zdjęć.
Uzasadnionego interesu (art. 6 ust. 1 lit. f RODO) – w zakresie zapobiegania oszustwom, ulepszania Aplikacji, pomiarów statystycznych.
Obowiązków prawnych (art. 6 ust. 1 lit. c RODO) – w zakresie rachunkowości i podatków.

V. Ochrona przed wykorzystywaniem seksualnym dzieci (CSAE)
Kategoryczny zakaz
 Greenbin.app surowo zabrania wszelkich treści lub działań związanych z wykorzystywaniem seksualnym dzieci, ich eksploatacją lub wyrządzaniem im krzywdy.


Środki ochrony nieletnich


Aplikacja nie jest przeznaczona dla osób poniżej 16. roku życia bez zgody rodziców lub opiekunów.
Nie gromadzimy danych osobowych nieletnich bez potwierdzonej zgody rodziców.
Wszelkie podejrzane działania wobec dzieci będą niezwłocznie badane i zgłaszane organom ścigania.
Mechanizm zgłaszania i reagowania
 Jeśli zauważysz treści lub zachowania naruszające tę politykę, zgłoś to:


E-mail: hello@greenbin.app
Facebook Messenger: https://m.me/greenbinapp
Współpraca z organami ścigania
 Naruszenia CSAE mogą być zgłaszane odpowiednim organom państwowym.



VI. Odbiorcy danych osobowych
Dane mogą być przekazywane do:
Dostawców infrastruktury IT (Amazon Web Services, Google Cloud, PowerBI).
Partnerów analitycznych i marketingowych.
Organów państwowych, jeśli wymaga tego prawo.
Usług płatniczych do przetwarzania transakcji.

VII. Okres przetwarzania danych osobowych
Dane są przetwarzane przez okres korzystania z Aplikacji oraz mogą być przechowywane dłużej w przypadkach przewidzianych prawem.

VIII. Prawa użytkowników
Użytkownik ma prawo do:
Dostępu do swoich danych, ich poprawiania i usunięcia.
Sprzeciwu wobec przetwarzania danych.
Wycofania zgody na przetwarzanie.
Złożenia skargi do organów nadzorczych.

IX. Postanowienia końcowe
Polityka może ulec zmianie, a użytkownicy zostaną powiadomieni z 14-dniowym wyprzedzeniem.
Data wejścia w życie: 01 stycznia 2025 r.


`
export const UA =`Політика конфіденційності мобільного додатка Greenbin.app
I. Загальні положення
Ця Політика конфіденційності (далі — "Політика конфіденційності") встановлює правила захисту та обробки персональних даних у мобільному додатку Greenbin.app (визначеному нижче).


Адміністратором персональних даних, що обробляються в межах Додатка, є Greenbin sp. z o.o., головний офіс якої розташований за адресою Poznan, ul. Szelągowska 49 (61-626 Познань), зареєстрована у Реєстрі підприємців Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy Poznań-Nowe Miasto i Wilda w Poznaniu, VIII Wydział Gospodarczy Krajowego Rejestru Sądowego під номером KRS: 0000803248, REGON: 384329544, NIP: 9721303267 (далі — "Адміністратор" або "Постачальник послуг").


Адміністратор надає такі контактні дані:
 e-mail: hello@greenbin.app


II. Визначення термінів
Додаток – мобільний додаток під назвою "Greenbin.app", у якому користувачі, приймаючи виклики, отримують "зелені бали", що можуть бути обміняні на призи або передані на благодійність.
GDPR – Регламент (ЄС) 2016/679 Європейського парламенту і Ради від 27 квітня 2016 року щодо захисту фізичних осіб у зв'язку з обробкою персональних даних та щодо вільного руху таких даних.
Зелені бали – засіб обміну, що має споживчу цінність лише в межах Програми. Це не є віртуальною валютою. Користувачі можуть обмінювати зібрані зелені бали на призи або передавати їх на благодійність.
Виклики – дії у сфері збору та переробки пластикових пляшок, що надаються у Додатку, за виконання яких користувачі отримують зелені бали.
Приз – нагорода, яку користувач має право отримати після виконання певних умов. Користувач отримує приз, коли обмінює відповідну кількість зелених балів.
Магазин Greenbin – платформа в межах Додатка, що дозволяє користувачам замовляти нагороди в обмін на зібрані Зелені бали.
Користувач – фізична особа, яка відповідає зазначеним умовам для участі в Додатку, зокрема, зареєструвалася через Форму реєстрації.
Push-сповіщення – повідомлення про Програму, включно з інформуванням користувача про доступні виклики та оновлення.
Клієнт – підприємець у розумінні статті 4 Закону від 6 березня 2018 року - Закон про підприємців.


III. Обсяг оброблюваних даних у додатку
Додаток обробляє такі персональні дані:


Дані, надані під час реєстрації: ім'я користувача, адреса електронної пошти, стать, дата народження.
Автоматично зібрані дані: місцезнаходження пристрою, IP-адреса, інформація про зібрані Зелені бали, транзакції та благодійні внески.
Добровільно надані дані: зображення профілю, місто проживання, освіта, сімейний стан, інформація про дітей.
Дані, необхідні для отримання нагород: ім'я, прізвище, електронна пошта, поштова адреса.
Дані для перевірки участі користувача: фото пляшок, селфі, місцезнаходження кошиків.
Дані використовуються для:


Забезпечення належного функціонування Додатка.
Підвищення рівня безпеки та запобігання шахрайству.
Аналізу поведінки користувачів для покращення сервісу.
Надання персоналізованої реклами та акційних пропозицій.
Виконання юридичних зобов'язань.

IV. Правові основи обробки персональних даних
Обробка даних здійснюється на підставі:
Виконання договору (ст. 6 (1) (b) GDPR) – для реєстрації акаунту, участі у викликах, отримання нагород.
Згоди користувача (ст. 6 (1) (a) GDPR) – для маркетингу, добровільного надання даних, перевірки фотографій.
Законних інтересів (ст. 6 (1) (f) GDPR) – для запобігання шахрайству, покращення Додатка, статистичних вимірювань.
Юридичних зобов’язань (ст. 6 (1) (c) GDPR) – для податкового та бухгалтерського обліку.

V. Захист від сексуального насильства та експлуатації дітей (CSAE)
Категорична заборона
 Greenbin.app суворо забороняє будь-який контент або діяльність, пов’язану з сексуальним насильством над дітьми, їх експлуатацією або завданням шкоди.


Заходи для захисту неповнолітніх
Додаток не призначений для осіб молодше 16 років без згоди батьків або опікунів.
Ми не збираємо особисті дані неповнолітніх без підтвердженої згоди батьків.
Будь-які підозрілі дії щодо дітей будуть негайно розслідувані та повідомлені правоохоронним органам.


Механізм скарг та реагування
 Якщо ви виявили контент або поведінку, що порушує цю політику, повідомте нас:
Email: hello@greenbin.app
Facebook Messenger: https://m.me/greenbinapp


Співпраця з правоохоронними органами
 Порушення CSAE можуть бути передані до відповідних органів влади.


VI. Одержувачі персональних даних
Дані можуть передаватися:
Постачальникам IT-інфраструктури (Amazon Web Services, Google Cloud, PowerBI).
Аналітичним і маркетинговим партнерам.
Державним органам, якщо цього вимагає законодавство.
Платіжним сервісам для обробки транзакцій.

VII. Період обробки персональних даних
Дані обробляються протягом періоду використання Додатка та можуть зберігатися довше у випадках, передбачених законодавством.

VIII. Права користувачів
Користувач має право:
Доступу, виправлення та видалення своїх даних.
Заперечувати проти обробки.
Відкликати згоду.
Подати скаргу в контролюючі органи.
IX. Заключні положення
Політика може змінюватися, і користувачі будуть сповіщені за 14 днів.
Дата набуття чинності: 01 січня 2025 року.
`